.ga-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 15px;
  gap: 10px;
  width: 100%;
  height: 40px;
  background: #fff;
  border: 1px solid #d4d3da;
  border-radius: 8px;
}

.ga-input-search {
  height: inherit;
  font-size: 12px;
}

.row .ga-input-container {
  height: 100%;
}

.ga-strict-input-label {
  min-height: 24px;
}

.ga-strict-input-label-margin-top {
  margin-top: 24px;
}

.ga-input-container {
  display: flex;
  flex-direction: column;
  padding: 0 0 8px 0;
  width: 100%;
  min-height: 40px;
  flex: none;
  order: 1;
  flex-grow: 0;
  justify-content: flex-start;
  position: relative;
}

.ga-input-wrapper {
  display: block;
  width: 100%;
}

.ga-input:hover {
  border: 1px solid #777777;
  box-shadow: none;
}

.ga-input:active {
  border: 1px solid #FF6A13;
  box-shadow: none;
}

.ga-input:focus-visible {
  outline: none;
  box-shadow: none;
}

.ga-input:focus {
  box-shadow: none;
}

.ga-align-flex-end {
  align-items: flex-end;
}

.has-errors {
  border-color: #dc3545;
}

.form-control.is-invalid {
  border-color: #dc3545;
  box-shadow: none;
  padding-right: 15px;
  background-image: none;
}

.form-control.is-invalid:active {
  border: 1px solid #FF6A13;
  box-shadow: none;
}

.form-control.is-invalid:focus {
  border-color: #FF6A13;
  box-shadow: none;
}

.invalid-feedback {
  display: block;
  list-style-type: disc;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #dc3545;
  padding: 0;
  margin: 5px 0 10px 18px;
}

.errors-list {
  width: 100%;
  margin-top: 0.25rem;
  color: #dc3545;
  list-style: none;
  display: grid;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

/* Provide sufficient contrast against white background */
a {
  color: cornflowerblue;
  cursor: pointer;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.table-info, .table-info > td, .table-info > th {
  background-color: #bee5eb;
}

.table td, .table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
.border-top {
  border-top: 1px solid #e5e5e5;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

button.accept-policy {
  font-size: 1rem;
  line-height: inherit;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}

.container {
  max-width: inherit;
}

main {
  padding-left: 15px;
  padding-right: 15px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
  line-height: 60px; /* Vertically center the text there */
}

.tabs {
  display: inline-block;
}

.tabs > div {
  padding-top: 10px;
}

.tabs ul:not(strict-errors > ul) {
  margin: 0px;
  padding: 0px;
}
.tabs ul:not(strict-errors > ul):after {
  content: "";
  display: block;
  clear: both;
  height: 5px;
  background: #46c765;
}

.tabs ul li:not(strict-errors > ul > li) {
  margin: 0px;
  cursor: pointer;
  display: block;
  float: left;
  padding: 10px 15px;
  background: #e9eaeb;
  color: #707070;
}
.tabs ul li:not(strict-errors > ul > li):hover {
  background: #d6d6d7;
}
.tabs ul li:not(strict-errors > ul > li).active, .tabs ul li:not(strict-errors > ul > li).active:hover {
  background: #46c765;
  color: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.timeline-marker {
  text-decoration: underline;
  text-decoration-color: blue;
  text-decoration-thickness: 3px;
}

.ga-table-row-container:hover {
  background: #ccddff !important;
}
.ga-table-row-container:nth-child(even):hover {
  background: #ccddff !important;
}

.ga-cell-text-wrapper {
  font-family: sans-serif !important;
}