@use "node_modules/@koddington/ga-common/themes/ga-default";

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

/* Provide sufficient contrast against white background */
a {
  color: cornflowerblue;
  cursor: pointer;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.table-info, .table-info>td, .table-info>th {
  background-color: #bee5eb;
}

.table td, .table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.border-top {
  border-top: 1px solid #e5e5e5;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

button.accept-policy {
  font-size: 1rem;
  line-height: inherit;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}

.container {
  max-width: inherit;
}

main {
  padding-left: 15px;
  padding-right: 15px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
  line-height: 60px; /* Vertically center the text there */
}



.tabs {
  display: inline-block;
}

.tabs > div {
  padding-top: 10px;
}

.tabs ul {
  &:not(strict-errors > ul) {
    margin: 0px;
    padding: 0px;

    &:after {
      content: "";
      display: block;
      clear: both;
      height: 5px;
      background: #46c765;
    }
  }
}

.tabs ul li {
  &:not(strict-errors > ul > li) {
    margin: 0px;
    cursor: pointer;
    display: block;
    float: left;
    padding: 10px 15px;
    background: #e9eaeb;
    color: #707070;

    &:hover {
      background: #d6d6d7;
    }

    &.active, &.active:hover {
      background: #46c765;
      color: #fff;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.timeline-marker {
  text-decoration: underline;
  text-decoration-color: blue;
  text-decoration-thickness: 3px;
}

// ga-table colors
.ga-table-row-container {
  &:hover{
    background: #ccddff !important;
  }
  &:nth-child(even) {
    &:hover{
      background: #ccddff !important;
    }
  }
}

.ga-cell-text-wrapper {
  font-family: sans-serif !important;
}
